export const PATHS = {
  acceptInvitation: "/invitations/:token",
  authorization: "/authorizations/:code",
  authorizations: "/authorizations",
  claim: "/authorizations/:authId/claim/new",
  finishSigningUp: "/signup/continue",
  forgotPassword: "/password/new",
  hipaa: "https://joinstorkclub.com/legal/hipaa/",
  home: "/",
  outcome: "/authorizations/:code/outcome/new",
  privacyGlobal: "https://joinstorkclub.com/legal/privacy_global/",
  privacyUS: "https://joinstorkclub.com/legal/privacy/",
  referral: "/referrals/:code",
  referrals: "/referrals",
  restorePassword: "/password/edit",
  signIn: "/signin",
  signOut: "/signout",
  signUp: "/signup",
  tos: "https://joinstorkclub.com/legal/tos/",
  unlock: "/unlock",
};
