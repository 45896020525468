import { wrapCreateBrowserRouter } from "@sentry/react";
import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  useRouteError,
} from "react-router-dom";
import { useSignOut } from "../authentication/hooks/useSignOut";
import { NotFoundPage, Error500Page } from "../components/ErrorPage";
import {
  NavigateToSignIn,
  RestoreLocation,
} from "../components/RestoreLocation";
import checkHasNewVersion from "../utils/checkHasNewVersion";
import { PATHS } from "./PATHS";
import RouteLoader from "./RouteLoader";

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(
  createBrowserRouter,
);

const SignOutRoute = () => {
  const { mutate: signOut } = useSignOut();
  useEffect(() => {
    signOut();
  }, [signOut]);
  return null;
};

class NeedToReloadError extends Error {
  constructor(cause: unknown) {
    super("Need to reload");
    this.cause = cause;
  }
}

const LazyRouteErrorBoundary = () => {
  const error = useRouteError();
  if (error instanceof NeedToReloadError) {
    // eslint-disable-next-line no-console
    console.debug("there is a new version, need to reload");
    window.location.reload();
    return null;
  }
  // generic error, need to pass it to the next error boundary
  throw error;
};

async function wrapLazyRoute(
  routeComponent: Promise<{ default: React.ComponentType }>,
): Promise<{ Component: React.ComponentType }> {
  try {
    const module = await routeComponent;
    return { Component: module.default };
  } catch (error) {
    const hasANewVersion = await checkHasNewVersion();
    if (hasANewVersion) {
      throw new NeedToReloadError(error);
    } else {
      // just throw the error, it is not caused by a new version
      throw error;
    }
  }
}
export const authenticatedRoutes = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<RestoreLocation />}
      ErrorBoundary={Error500Page}
      // errorElement={<ErrorBoundary fallback={<Error500Page />} />}
    >
      <Route
        element={<RouteLoader />}
        errorElement={<LazyRouteErrorBoundary />}
      >
        <Route
          index
          lazy={() => wrapLazyRoute(import("../components/HomePage"))}
        />
        <Route
          lazy={() =>
            wrapLazyRoute(
              import(
                "../authentication/components/AcceptInvitationPage"
              ),
            )
          }
          path={PATHS.acceptInvitation}
        />
        <Route
          lazy={() =>
            wrapLazyRoute(
              import("../claims/components/ClaimFormPage"),
            )
          }
          path={PATHS.claim}
        />
        <Route
          lazy={() => wrapLazyRoute(import("../outcome"))}
          path={PATHS.outcome}
        />
        <Route
          lazy={() =>
            wrapLazyRoute(
              import(
                "../authorizations/components/AuthorizationPage"
              ),
            )
          }
          path={PATHS.authorization}
        />
        <Route
          lazy={() =>
            wrapLazyRoute(
              import(
                "../authorizations/components/AuthorizationsPage"
              ),
            )
          }
          path={PATHS.authorizations}
        />
        <Route
          lazy={() =>
            wrapLazyRoute(
              import("../referrals/components/ReferralPage"),
            )
          }
          path={PATHS.referral}
        />
        <Route
          lazy={() =>
            wrapLazyRoute(
              import("../referrals/components/ReferralsPage"),
            )
          }
          path={PATHS.referrals}
        />
        <Route element={<SignOutRoute />} path={PATHS.signOut} />
        <Route element={<Navigate to="/" />} path={PATHS.signIn} />
        <Route element={<Navigate to="/" />} path={PATHS.signUp} />
        <Route
          element={<Navigate to="/" />}
          path={PATHS.forgotPassword}
        />
        <Route
          element={<Navigate to="/" />}
          path={PATHS.restorePassword}
        />
        <Route
          element={<Navigate to="/" />}
          path={PATHS.finishSigningUp}
        />
        <Route element={<NotFoundPage />} path="*" />
      </Route>
    </Route>,
  ),
);

export const notAuthenticatedRoutes = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route ErrorBoundary={Error500Page}>
      <Route element={<RouteLoader />}>
        <Route
          lazy={() =>
            wrapLazyRoute(
              import(
                "../authentication/components/AcceptInvitationPage"
              ),
            )
          }
          path={PATHS.acceptInvitation}
        />
        <Route
          lazy={() =>
            wrapLazyRoute(
              import(
                "../authentication/components/ChangePasswordPage"
              ),
            )
          }
          path={PATHS.restorePassword}
        />
        <Route
          lazy={() =>
            wrapLazyRoute(
              import("../authentication/components/SignInPage"),
            )
          }
          path={PATHS.signIn}
        />
        <Route
          lazy={() =>
            wrapLazyRoute(
              import("../authentication/components/SignUpPage"),
            )
          }
          path={PATHS.signUp}
        />
        <Route
          lazy={() =>
            wrapLazyRoute(
              import("../authentication/components/FinishSignUpPage"),
            )
          }
          path={PATHS.finishSigningUp}
        />
        <Route
          lazy={() =>
            wrapLazyRoute(
              import(
                "../authentication/components/ForgotPasswordPage"
              ),
            )
          }
          path={PATHS.forgotPassword}
        />
        <Route
          lazy={() =>
            wrapLazyRoute(
              import("../authentication/components/UnlockPage"),
            )
          }
          path={PATHS.unlock}
        />
        <Route element={<NavigateToSignIn />} path="*" />
      </Route>
    </Route>,
  ),
);
